import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PolicyModal = ({ show, onHide, type }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setError(null);

      const filePath = type === "termsofuse" ? "./assets/uploads/data/termsofuse.json" : "./assets/uploads/data/privacypolicy.json";

      fetch(filePath)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(data => {
          setData(data);
          setLoading(false);
        })
        .catch(error => {
          setError("Failed to load the data. Please try again later.");
          setLoading(false);
        });
    }
  }, [show, type]);

  // Helper function to replace `\n` with `<br>`
  const formatContent = (text) => {
    return text.replace(/\n\n/g, "<br><br>").replace(/\n/g, "<br>");
  };

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      dialogClassName="modal-dialog-scrollable" 
      backdrop="static" 
      centered
      className="modern-modal"
    >
      <Modal.Header className="border-0 shadow-sm d-flex align-items-center justify-content-between">
        <Modal.Title className="fs-3 text-center w-100">
          {data?.title || (type === "termsofuse" ? "Terms of Use" : "Privacy Policy")}
        </Modal.Title>
        <button type="button" className="btn-close-custom" aria-label="Close" onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </Modal.Header>
      <Modal.Body className="p-4">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="text-center visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <>
            <p className="text-muted text-center"><em>Effective Date: {data.effectiveDate || 'Not specified'}</em></p>
            {data.sections.map((section, index) => (
              <div key={index}>
                <h5 className="fw-bold mt-3">{index + 1}. {section.title}</h5>
                <p dangerouslySetInnerHTML={{ __html: formatContent(section.content) }} />
                {section.listItems && (
                  <ul>
                    {section.listItems.map((item, idx) => (
                      <li key={idx} dangerouslySetInnerHTML={{ __html: formatContent(item) }} />
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PolicyModal;