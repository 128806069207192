import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPaperPlane, faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [expandedProducts, setExpandedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    subject: '',
    comments: '',
    turnstileToken: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const captchaRef = useRef(null); // Create a ref for the CAPTCHA

  // Load CAPTCHA script and products on component mount
  useEffect(() => {
    const loadCaptchaScript = () => {
      if (!document.getElementById("turnstileScript")) {
        const script = document.createElement("script");
        script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
        script.async = true;
        script.defer = true;
        script.id = "turnstileScript";
        document.body.appendChild(script);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch('./assets/uploads/data/products.json');
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    loadCaptchaScript();
    fetchProducts();
  }, []);

  // Render CAPTCHA dynamically
  const renderCaptcha = () => {
    try {
      if (window.turnstile && captchaRef.current) {
        window.turnstile.render(captchaRef.current, {
          sitekey: process.env.NODE_ENV === 'development' ? '1x00000000000000000000AA' : '0x4AAAAAAAh_AGvoiS5WvTNT',
          theme: 'light',
          callback: (token) => {
            setFormData((prevData) => ({ ...prevData, turnstileToken: token }));
            setIsCaptchaValid(true);
          },
          'error-callback': () => {
            Swal.fire({
              title: "Captcha Error",
              text: "An error occurred with the CAPTCHA. Please try again.",
              icon: "error",
            });
            setIsCaptchaValid(false);
          },
          'expired-callback': () => {
            setIsCaptchaValid(false);
          },
        });
      }
    } catch (error) {
      console.error("Error rendering CAPTCHA:", error);
      Swal.fire({
        title: "Captcha Initialization Failed",
        text: "Failed to initialize CAPTCHA. Please try again later.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (showModal) {
      renderCaptcha();
    } else {
      // Reset CAPTCHA when modal is closed
      setIsCaptchaValid(false);
      setFormData((prevData) => ({ ...prevData, turnstileToken: '' }));
    }
  }, [showModal]);

  const handleReadMore = (productId) => {
    setExpandedProducts((prevState) =>
      prevState.includes(productId)
        ? prevState.filter((id) => id !== productId)
        : [...prevState, productId]
    );
  };

  const showMoreProducts = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleEnquireClick = (product) => {
    setSelectedProduct(product);
    setFormData({
      name: '',
      email: '',
      number: '',
      subject: `Product enquiry for - ${product.name}`,
      comments: '',
      turnstileToken: '',
    });
    setFormErrors({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (!value) {
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: `${name} is required` }));
    } else {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'turnstileToken') {
        errors[key] = `${key} is required`;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (!isCaptchaValid) {
      Swal.fire({
        title: "Captcha Required!",
        text: "Please complete the CAPTCHA verification.",
        icon: "warning",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://independentindia.in/api/form_process.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Submission failed. Please try again later.");
      }

      setShowModal(false);
      Swal.fire({
        title: "Success!",
        text: "Your enquiry was submitted successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });

      setFormData({
        name: '',
        email: '',
        number: '',
        subject: '',
        comments: '',
        turnstileToken: '',
      });
      setIsCaptchaValid(false);
      setFormErrors({});
    } catch (error) {
      console.error("Error submitting the form:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Validation function to check form completeness
  const isFormValid = () => {
    const { name, email, number, comments, turnstileToken } = formData;

    // Check if all fields are non-empty and CAPTCHA is valid
    return (
      name.trim() &&
      email.trim() &&
      number.trim() &&
      comments.trim() &&
      isCaptchaValid &&
      turnstileToken
    );
  };

  const allProductsVisible = visibleCount >= products.length;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section id="products" className="content bg-light">
      <div className="container">
        <h2>What do we offer?</h2>
        <p>
          At Independent India, we pride ourselves on offering a comprehensive range of high-quality electrical goods tailored to meet various industrial and commercial needs.
        </p>
        <div className="row" id="product-list">
          {products.slice(0, visibleCount).map((product) => {
            const imageUrl = product.images && product.images.length > 0 ? product.images[0] : 'https://via.placeholder.com/350x350';
            const isExpanded = expandedProducts.includes(product.id);

            return (
              <div key={product.id} id={product.id} className="col-md-4 mb-4 product">
                <div className="card product shadow-sm">
                  <img src={imageUrl} className="card-img-top" alt={product.name} loading="lazy" />
                  <div className="card-body text-center">
                    <h5 className="card-title">{product.name}</h5>
                    <p className="category-text"><small className="text-muted">Category: {product.category}</small></p>
                    <p className={`card-text ${isExpanded ? 'expanded' : ''}`}>
                      {isExpanded ? product.description : product.description.slice(0, 90) + '...'}
                    </p>
                    {product.description.length > 100 && (
                      <button type='button' className="read-more btn btn-link link-success p-0" onClick={() => handleReadMore(product.id)} style={{ display: 'block' }}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                    <button type="button" className="btn btn-success mt-2" onClick={() => handleEnquireClick(product)}>
                      Enquire now <FontAwesomeIcon icon={faPaperPlane} size="1x" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div> {!allProductsVisible && (
          <div className="view-more text-center">
            <button id="view-more-btn" className="mb-3" onClick={showMoreProducts}>
              View More products <FontAwesomeIcon icon={faArrowRight} size="1x" />
            </button>
          </div>
        )}
      </div>

      {/* Modern Modal */}
      {selectedProduct && (
        <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-dialog-scrollable" className="modern-modal" backdrop="static" centered>
          <Modal.Header className="border-0 shadow-sm d-flex align-items-center justify-content-between">
            <Modal.Title className="fs-3 text-center w-100">Product Enquiry</Modal.Title>
            <button type="button" className="btn-close-custom" aria-label="Close" onClick={handleCloseModal}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </Modal.Header>

          <Modal.Body className="p-4">
            <div className="modal-form w-100">
              <form id="enquiryForm" onSubmit={handleSubmit} noValidate>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="name"
                    className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
                    placeholder="John Doe"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    maxLength="100"
                  />
                  <label htmlFor="enquiryName">Name<span className="text-danger"> *</span></label>
                  {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                    placeholder="johndoe@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    maxLength="100"
                  />
                  <label htmlFor="enquiryEmail">Email address<span className="text-danger"> *</span></label>
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="number"
                    className={`form-control ${formErrors.number ? "is-invalid" : ""}`}
                    placeholder="+919876543210"
                    value={formData.number}
                    onChange={handleChange}
                    required
                    maxLength="15"
                  />
                  <label htmlFor="enquiryNumber">Contact number<span className="text-danger"> *</span></label>
                  {formErrors.number && <div className="invalid-feedback">{formErrors.number}</div>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    placeholder="Product Enquiry"
                    value={formData.subject}
                    readOnly
                  />
                  <label htmlFor="enquirySubject">Subject<span className="text-danger"> *</span></label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    name="comments"
                    className={`form-control ${formErrors.comments ? "is-invalid" : ""}`}
                    placeholder="Your message"
                    value={formData.comments}
                    onChange={handleChange}
                    required
                    maxLength="500"
                    style={{ height: '120px' }}
                  />
                  <label htmlFor="enquiryComments">Your message<span className="text-danger"> *</span></label>
                  {formErrors.comments && <div className="invalid-feedback">{formErrors.comments}</div>}
                </div>

                {/* Turnstile CAPTCHA */}
                <div
                  ref={captchaRef}
                  className="mb-3 d-flex justify-content-center"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                ></div>

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isSubmitting || !isFormValid()}
                >
                  {isSubmitting ? (
                    <>
                      <FontAwesomeIcon icon={faCircleNotch} spin /> Sending...
                    </>
                  ) : (
                    <>
                      Send <FontAwesomeIcon icon={faPaperPlane} />
                    </>
                  )}
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </section>
  );
};

export default Products;